import { useCallback } from 'react';
import { useRouter } from 'next/router';
import {
	BF_LENSES_STEP_TITLE,
	BF_PRESCRIPTIONS_STEP_TITLE,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	LENSES_PACKAGES,
} from '@constants';
import { Caption, EducationModal, Flex, Lenses, Prescriptions, PrescriptionsLensExpansion, Title } from '@components';
import { useBFContext } from '@context';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import { DEFAULT_INFO_TABS, INFO_TAB_DESCRIPTION_COPY, LENS_INFO_TABS, VARIFOCAL_INFO_TABS } from '@utils/constants/info-module';
import styles from './PrescriptionStep.module.scss';

const PrescriptionStep = () => {
	const { rxType, step, journey, rxOptions, lensPackage } = useBFContext();
	const { locale } = useRouter();
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isSubmitStep = step === BUILD_FLOW_STEPS.SUBMIT;
	const isCollectionsStep = step === BUILD_FLOW_STEPS.TOP_FRAMES;
	const isPackagesStep = step === BUILD_FLOW_STEPS.PACKAGE;
	const isLensesStep = step === BUILD_FLOW_STEPS.CUSTOMIZE;
	const isPrescriptionsStep = step === BUILD_FLOW_STEPS.LENS || isPackagesStep || isLensesStep;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const shouldDisplayLenses = ((isLensesStep && isSunLens) || isCollectionsStep) && (isReadersSelected || !isSunLens);
	const currentLensOptions = rxOptions.find(
		option => option.optionValue === rxType || option.optionValue === RX_TYPE.SINGLE_VISION
	).lenses;

	const getEducationTabs = useCallback(() => {
		const tabs = LENS_INFO_TABS;
		const filteredOptions = currentLensOptions.filter(option => {
			if (!isSunLens) {
				if (option.name === 'Premium Plus') {
					return false;
				}
				if (lensPackage === LENSES_PACKAGES.BASIC && option.name === BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE) {
					return false;
				}
			}
			return true;
		});

		return filteredOptions.map(option => {
			return tabs.find(tab => tab.title.includes(option.name));
		});
	}, [currentLensOptions, lensPackage, isSunLens]);

	return (
		<div className={styles.container}>
			{
				<Flex align='end' justify='between' gap={3} className={styles.title}>
					<Flex column>
						<Title
							style={{
								opacity: step !== BUILD_FLOW_STEPS.LENS && !isSunLens ? 0.5 : 1,
								fontSize: '1.4rem',
							}}
						>
							{isPrescriptionsStep || isSubmitStep || isSunLens
								? BF_PRESCRIPTIONS_STEP_TITLE
								: BF_LENSES_STEP_TITLE}
						</Title>
						{!(isPrescriptionsStep || isSunLens) && !isSubmitStep && <Caption small>(Select all that apply)</Caption>}
					</Flex>
					{isPrescriptionsStep ? (
						<div onClick={e => e.stopPropagation()}>
							{isLensesStep && !isSunLens ? (
								<EducationModal
									tabs={isSunLens ? getEducationTabs() : [...DEFAULT_INFO_TABS, ...getEducationTabs()]}
									title={'The Handy Lens Add-Ons'}
									tab={0}
									subTitle={'Enhance your Pair with these lens extras!'}
									infoCopy={INFO_TAB_DESCRIPTION_COPY}
								/>
							) : (
								<EducationModal
									tabs={locale === 'en-GB' ? VARIFOCAL_INFO_TABS : DEFAULT_INFO_TABS}
									title={'The Prescription Lens Lineup'}
									tab={0}
									subTitle={'Here’s our prescription lens lowdown!'}
									infoCopy={INFO_TAB_DESCRIPTION_COPY}
								/>
							)}
						</div>
					) : null}
				</Flex>
			}
			<div className={styles['options-container']}>
				{isSunLens ? (
					<>
						<Prescriptions />
						{shouldDisplayLenses && <Lenses />}
					</>
				) : (
					<PrescriptionsLensExpansion />
				)}
			</div>
		</div>
	);
};

export default PrescriptionStep;
