/* eslint-disable max-lines */
import { CUSTOMER_JOURNEYS } from '@constants';

export const BLUE_LIGHT_INFO_TABS = [
	{
		title: 'For Home',
		list: [
			{
				description: 'Perfect for those who spend many hours working in front of a computer or screen a day',
				checkmark: true,
			},
			{ description: 'Increases focus and decreases headaches', checkmark: true },
			{ description: 'Reduces eye discomfort, dryness, and tiredness', checkmark: true },
			{
				description:
					'Studies have shown that Blue Light glasses might reduce the likelihood of Age-Related Macular Degeneration',
				checkmark: true,
			},
		],
	},
	{
		title: 'For Work',
		list: [
			{
				description:
					'Get better sleep with our Blue Light glasses by blocking out light rays that suppress the production of melatonin',
				checkmark: true,
			},
			{ description: 'Great for reducing eye strain while watching movies', checkmark: true },
			{
				description: 'Enhance your focus while playing videogames or video calls with friends and family',
				checkmark: true,
			},
			{
				description: 'Excellent option for kids who spend lots of time in front of tablets, computers, or TV',
				checkmark: true,
			},
		],
	},
	{
		title: 'Single-Vision',
		list: [
			{ description: 'Single-vision polycarbonate lenses', checkmark: true },
			{
				description: 'All lenses include superhydrophobic, anti-reflective, and scratch-resistant treatments',
				checkmark: true,
			},
			{ description: 'Available for an additional $49', checkmark: true },
		],
	},
	{
		title: 'Progressives',
		list: [
			{
				description:
					'A progressive lens does the job of both single-vision lenses and reading glasses so that you only need one pair of glasses on hand',
				checkmark: true,
			},
			{ description: 'A better option without a bifocal line for bifocal wearers', checkmark: true },
			{ description: 'Available for an additional $249', checkmark: true },
		],
	},
];

export const INFO_TAB_DESCRIPTION_COPY =
	'Proper selection and use of eyewear is critical to your eye safety. If your occupation, sports, or other activities expose you to the risk of flying objects or physical impact, your eye safety may require the use of special spectacle lens materials. We strongly recommend the use of impact resistant Standard lenses simply because your safety, vision and the health of your eyes is our main concern. Eyeglass lenses made from Basic or Premium materials are not as impact resistant as our Standard material.';

export const DEFAULT_INFO_TABS = [
	{
		title: 'Single-Vision',
		list: [
			{
				description: `• For prescriptions from -14.50D to +5.00D`,
				checkmark: true,
			},
			{
				description: `• These lenses can be made to correct distance, intermediate or near vision as a single focus lens`,
				checkmark: true,
			},
			{
				description: `• High quality UV-blocking lens materials available`,
				checkmark: true,
			},
			{
				description: `• Including our premium Anti-Reflection coatings, which are also scratch-resistant and easy to clean`,
				checkmark: true,
			},
		],
	},
	{
		title: 'Progressives',
		list: [
			{
				description: `• Our Premium quality varifocals/progressives are made using Digital Freeform Technology
`,
				checkmark: true,
			},
			{
				description: `• These are the upgrade to old bifocals and trifocals`,
				checkmark: true,
			},
			{
				description: `• If your prescription had an ADD value and you are over 40 these would be ideal for you!`,
				checkmark: true,
			},
			{
				description: `• All of your visual needs taken care of in one lens, from closely reading a book to cruising down the road, do it all in your varifocal Pair`,
				checkmark: true,
			},
			{
				description: `• Including our Premium anti-reflection coatings, which are also scratch-resistant and easy to cleans`,
				checkmark: true,
			},
		],
	},
	{
		title: 'Readers',
		list: [
			{
				description: `• Magnifying lenses to help you see to read`,
				checkmark: true,
			},
			{
				description: `• No prescription required, just pick your strength a go!`,
				checkmark: true,
			},
			{
				description: `• Reading strength from +0.25D to +2.75D`,
				checkmark: true,
			},
			{
				description: `• Including our premium anti-reflection coatings, which are also scratch-resistant and easy to clean`,
				checkmark: true,
			},
		],
	},
];

export const VARIFOCAL_INFO_TABS = [
	{
		title: 'Single-Vision',
		list: [
			{
				description: `• For prescriptions from -14.50D to +5.00D`,
				checkmark: true,
			},
			{
				description: `• These lenses can be made to correct distance, intermediate or near vision as a single focus lens`,
				checkmark: true,
			},
			{
				description: `• High quality UV-blocking lens materials available`,
				checkmark: true,
			},
			{
				description: `• Including our premium Anti-Reflection coatings, which are also scratch-resistant and easy to clean`,
				checkmark: true,
			},
		],
	},
	{
		title: 'Varifocals',
		list: [
			{
				description: `• Our Premium quality varifocals/progressives are made using Digital Freeform Technology
`,
				checkmark: true,
			},
			{
				description: `• These are the upgrade to old bifocals and trifocals`,
				checkmark: true,
			},
			{
				description: `• If your prescription had an ADD value and you are over 40 these would be ideal for you!`,
				checkmark: true,
			},
			{
				description: `• All of your visual needs taken care of in one lens, from closely reading a book to cruising down the road, do it all in your varifocal Pair`,
				checkmark: true,
			},
			{
				description: `• Including our Premium anti-reflection coatings, which are also scratch-resistant and easy to cleans`,
				checkmark: true,
			},
		],
	},
	{
		title: 'Readers',
		list: [
			{
				description: `• Magnifying lenses to help you see to read`,
				checkmark: true,
			},
			{
				description: `• No prescription required, just pick your strength a go!`,
				checkmark: true,
			},
			{
				description: `• Reading strength from +0.25D to +2.75D`,
				checkmark: true,
			},
			{
				description: `• Including our premium anti-reflection coatings, which are also scratch-resistant and easy to clean`,
				checkmark: true,
			},
		],
	},
];

export const LENS_INFO_TABS = [
	{
		title: 'Premium Plus',
		list: [
			{
				description:
					'These high-power, high-index lenses are ultra-thin, anti-reflective, and scratch-resistant. This option may be necessary for strong prescriptions with a power below -6.00 or above +3.00.',
				checkmark: true,
			},
		],
	},
	{
		title: 'Blue-Light Filtering',
		list: [
			{
				description:
					'Blue-light lenses filter out potentially harmful rays of visible light that are typically emitted during screen time and by the sun. If you spend a good chunk of time looking at your go-to electronic devices, this lens can reduce your exposure to blue-light and may help with eye fatigue and sleep issues.',
				checkmark: true,
			},
		],
	},
	{
		title: 'Light Responsive',
		list: [
			{
				description:
					'If you’re quickly moving from indoors to outdoors, it can be a hassle to have to swap out your glasses. These photochromic lenses darken when they hit UV light. This tint will deepen as the rays become stronger, making this Pair very practical!',
				checkmark: true,
			},
		],
	},
];

export const LENS_TAB_VARIANTS = [
	{
		title: 'Premium Plus',
		list: [
			{
				description: 'These high-power, high-index lenses are ultra-thin, anti-reflective, and scratch-resistant.',
				checkmark: true,
			},
		],
	},
	{
		title: 'Blue-Light Filtering',
		list: [
			{
				description:
					'If you spend a good chunk of time looking at your go-to electronic devices, this lens can reduce your exposure to blue light and may help with eye fatigue and sleep issues.',
				checkmark: true,
			},
		],
	},
	{
		title: 'Light Responsive',
		list: [
			{
				description:
					'If you’re quickly moving from indoors to outdoors, it can be a hassle to have to swap out your glasses. This tint will deepen as the rays become stronger, making this Pair very practical!',
				checkmark: true,
			},
		],
	},
];

export const SUN_LENS_INFO_TABS = [
	{
		title: 'Polarized',
		list: [
			{
				description: 'All of our sunglasses come standard with 100% polarized lenses',
				checkmark: true,
			},
			{
				description:
					'Polarized lenses provide optimal glare reduction which is essential for driving and outdoor activities',
				checkmark: true,
			},
			{
				description:
					'Polarized sunglasses may also give you a more accurate portrayal of colors than non-polarized sunglasses',
				checkmark: true,
			},
		],
	},
	{
		title: 'Reflective',
		list: [
			{
				description: 'All of our sunglasses come standard with 100% polarized lenses',
				checkmark: true,
			},
			{
				description: 'Combined with our polarized lenses, reflective lenses will provide the best glare protection',
				checkmark: true,
			},
			{
				description: 'Better visual clarity since much of the light is reflected rather than absorbed',
				checkmark: true,
			},
			{
				description: 'Have a sense of anonymity as your eyes are not visible',
				checkmark: true,
			},
		],
	},
	{
		title: 'Single-Vision',
		list: [
			{ description: 'All of our sunglasses come standard with 100% polarized lenses', checkmark: true },
			{
				description: 'Single-vision polycarbonate lenses',
				checkmark: true,
			},
			{
				description: 'All lenses include superhydrophobic, anti-reflective, and scratch-resistant treatments',
				checkmark: true,
			},
			{
				description: 'Available for an additional $99',
				description_ca: 'Available for an additional $99 CAD',
				checkmark: true,
				description_au: 'Available for an additional $265 AUD',
				description_gb: 'Available for an additional £135',
			},
		],
	},
	{
		title: 'Progressives',
		list: [
			{
				description: 'All of our sunglasses come standard with 100% polarized lenses',
				checkmark: true,
			},
			{
				description:
					'A progressive lens does the job of both single-vision lenses and reading glasses so that you only need one pair of glasses on hand',
				checkmark: true,
			},
			{ description: 'A better option without a bifocal line for bifocal wearers', checkmark: true },
			{
				description: 'Available for an additional $299',
				description_ca: 'Available for an additional $299 CAD',
				checkmark: true,
				description_au: 'Available for an additional $515 AUD',
				description_gb: 'Available for an additional £310',
			},
		],
	},
];

// Journey-Specific Info
export const INFO_MODULE_DATA = {
	[CUSTOMER_JOURNEYS.BLUELIGHT]: {
		id: 'blue_light_info_module',
		cmsSlug: 'blue-light-offering',
		tabs: BLUE_LIGHT_INFO_TABS,
		title: 'Blue Light Offerings',
	},
	[CUSTOMER_JOURNEYS.SUNGLASSES]: {
		id: 'sun_lens_info_module',
		cmsSlug: 'sun-lens-offering',
		tabs: SUN_LENS_INFO_TABS,
		title: 'Sun Lens Offerings',
	},
	[CUSTOMER_JOURNEYS.EYEGLASSES]: {
		id: 'lens_lineup_info_module',
		cmsSlug: 'lens-lineup',
		tabs: [...DEFAULT_INFO_TABS, ...LENS_INFO_TABS],
		title: 'The Rx Lens Lineup',
	},
};

export const SUBSCRIPTION_INFO_TABS = [
	{
		v2: false,
		title: 'All About Subscriptions',
		list: [
			{
				description:
					'Subscriptions are the perfect way to build your Pair collection while saving some coin. If you enroll, you’ll get one pre-selected Top Frame (for 10% off!) mailed to you every one, two, or three months. Feel free to swap, pause, or cancel at anytime.',
				checkmark: true,
				justify: true,
			},
			{
				description: `Before each subscription order ships, you'll receive an email where you can preview the next Top in your subscription. If the Top Frame that’s next up in your subscription isn’t quite what you’re looking for, you can swap for another one of our pre-selected best-sellers.`,
				checkmark: true,
				justify: true,
			},
		],
	},
	{
		v2: true,
		title: 'All About Subscriptions',
		list: [
			{
				description:
					'Subscriptions are the perfect way to build your Pair collection while saving some coin. If you enroll, you’ll get one pre-selected Top Frame (for 10% off!) mailed to you every month. Feel free to swap, pause, adjust your delivery frequency, or cancel at anytime.',
				checkmark: true,
				justify: true,
			},
			{
				description: `Before each subscription order ships, you'll receive an email where you can preview the next Top in your subscription. If the Top Frame that’s next up in your subscription isn’t quite what you’re looking for, you can swap for another one of our pre-selected best-sellers.`,
				checkmark: true,
				justify: true,
			},
		],
	},
];
