import cn from 'classnames';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Button, EducationModal, Flex } from '@components/common';
import { Heading } from '@components/typography';
import { useBFContext } from '@context';
import { SUBSCRIPTION_INFO_TABS } from '@utils/constants/info-module';
import {
	BF_SUBSCRIPTIONS_DONT_WANT_SUB,
	BUILD_FLOW_STEP_DIRECTION,
	formatCurrency,
	LOCALE_CODES,
	SUBSCRIPTION_BF,
} from '@utils/index';
import { Dict, NormalizedVariant } from '@ts/index';
import OptionCard from '../OptionCard';
import styles from './SubscriptionStep.module.scss';

const SubscriptionStep = () => {
	const {
		subscriptionProduct,
		sellingPlanId,
		setSellingPlanId,
		setSkipSubscription,
		setStepChange,
		setFirstTopSubscription,
		subscriptionProducts,
	} = useBFContext();
	const [selectedTop, setSelectedTop] = useState<NormalizedVariant>(subscriptionProduct || subscriptionProducts[0]);
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const plansToUse = selectedTop.sellingPlans.filter(plan => !plan.name.toLowerCase().includes('delivery'));
	const isV2Subscription = useFeatureIsOn('is-v2-subscription-test');

	const handleClick = useCallback((option: string) => {
		setSellingPlanId(option);
	}, []);

	const handleClickTop = useCallback(
		(option: NormalizedVariant, deefaultPlan?: Dict) => {
			setSellingPlanId(null);
			setFirstTopSubscription(option);
			setSelectedTop(option);

			if (isV2Subscription) {
				setSellingPlanId(deefaultPlan.id);
			}
		},
		[isV2Subscription]
	);

	return (
		<div className={cn(styles.container)}>
			<div className={styles.options}>
				<div>
					<Flex align='center' justify='between' gap={3} className={styles.title}>
						<Heading tag='h6'>
							{!isV2Subscription
								? SUBSCRIPTION_BF.CHOOSE_FIRST_SUBSCRIPTION
								: SUBSCRIPTION_BF.CHOOSE_FIRST_SUBSCRIPTION_V2}
						</Heading>
						<EducationModal
							tabs={SUBSCRIPTION_INFO_TABS.filter(tab => tab.v2 === isV2Subscription)}
							title={SUBSCRIPTION_BF.MODAL_TITLE}
							tab={0}
							subTitle={SUBSCRIPTION_BF.MODAL_SUBTITLE}
							withSubtitle
							data-subscription-bf-learnmore
							triggerLabel='Learn More'
						/>
					</Flex>
					<div className={styles['options-container']}>
						<RadioGroupPrimitive.Root asChild value={subscriptionProduct?.id}>
							<>
								{subscriptionProducts.map((product, index) => {
									let defaultPlan: Dict = product;
									if (isV2Subscription) {
										defaultPlan = product.sellingPlans.find(
											plan =>
												plan.name.toLowerCase().includes('1 month') &&
												!plan.name.toLowerCase().includes('delivery')
										);
									}
									return (
										<OptionCard
											key={`tops-${index}`}
											id={product.id}
											isSelected={subscriptionProduct?.id === product.id}
											title={product.name}
											value={product.name}
											onClick={() => handleClickTop(product, defaultPlan)}
											image={product.image.url}
											data-subscription-bf-initialtop={product.name}
											price={isV2Subscription && formatCurrency({ ...defaultPlan.price, locale }, showCurr)}
											priceDescription={isV2Subscription && 'Per delivery'}
										/>
									);
								})}
							</>
						</RadioGroupPrimitive.Root>
					</div>
				</div>
				{!isV2Subscription && (
					<div className={cn({ [styles['subcription-cadence-disabled']]: !subscriptionProduct?.id })}>
						<Flex align='center' justify='between' gap={3} className={styles.title}>
							<Heading tag='h6'>{SUBSCRIPTION_BF.SELECT_SUBSCRIPTION_PLAN}</Heading>
						</Flex>
						<div className={styles['options-container']}>
							<RadioGroupPrimitive.Root asChild value={sellingPlanId}>
								<>
									{plansToUse.map(({ id, description, name, price }, index) => (
										<OptionCard
											key={`selling-plan-${index}`}
											description={description}
											id={id}
											isSelected={sellingPlanId === id}
											price={formatCurrency({ ...price, locale }, showCurr)}
											title={name}
											value={id}
											onClick={() => handleClick(id)}
											data-subscription-bf-cadence={name}
											priceDescription='Per delivery'
										/>
									))}
								</>
							</RadioGroupPrimitive.Root>
						</div>
					</div>
				)}
			</div>
			<Flex className={styles['one-time-button']}>
				<Button
					color='white'
					linkStyle
					onClick={() => {
						setSkipSubscription(true);
						setStepChange(BUILD_FLOW_STEP_DIRECTION.NEXT);
					}}
					data-subscription-bf-optout
				>
					{BF_SUBSCRIPTIONS_DONT_WANT_SUB}
				</Button>
			</Flex>
		</div>
	);
};

export default SubscriptionStep;
